<template>
  <div>
    <el-dialog title="记农事" :visible="dialogVisible" width="60%" @close="closeDg" destroy-on-close :close-on-click-modal="false">
      <el-form ref="ruleForm" :model="form" label-width="120px" :rules="rules">
        <el-form-item label="农事名称" prop="farmingTypeId">
          <div class="flex-row-dia">
            <el-select v-model="form.farmingStage" placeholder="请选择农事节点" @change="stageChange">
              <el-option v-for="item in farmingTypes" :key="item.stageCode" :label="item.stageName" :value="item.stageCode"> </el-option>
            </el-select>
            <el-select v-model="form.farmingTypeId" placeholder="请选择农事名称">
              <el-option v-for="item in farmingTypeNames" :key="item.id" :label="item.name" :value="item.id"> </el-option>
            </el-select>
          </div>
        </el-form-item>

        <el-form-item label="操作人" prop="userId">
          <el-select v-model="form.userId" placeholder="请选择操作人" @change="userChange">
            <el-option v-for="item in sysUsers" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="地块名称" v-if="landInfo && landInfo.name">
          <el-input placeholder="请输入内容" style="width: 200px; margin-right: 4px" v-model="landInfo.name" :disabled="true"> </el-input>
        </el-form-item>
        <el-form-item label="地块面积" v-if="landInfo && landInfo.area">
          <el-input placeholder="请输入内容" style="width: 200px; margin-right: 4px" v-model="landInfo.area" :disabled="true"> </el-input>亩
        </el-form-item>
        <el-form-item label="添加农资">
          <el-button @click="addMeans()">+</el-button>
        </el-form-item>
        <el-form-item label="">
          <div class="select-means-wrap">
            <template v-for="item in meansList">
              <div v-if="item.selected" class="means-item" :key="item.id">
                <div class="left">
                  <div class="row1">
                    <span>农资：{{ item.name }}</span>
                    <span>规格：{{ item.countPerUnit }}{{ unitSpecs[item.unitSpecs] }}/{{ unitMeasurement[item.unitOfMeasurement] }}</span>
                  </div>
                  <div class="row2">
                    <span>数量：{{ item.meansNum }}{{ unitMeasurement[item.unitOfMeasurement] }}</span>
                    <span>总量：{{ item.totalMeansVal }}{{ unitSpecs[item.unitSpecs] }}</span>
                  </div>
                </div>
                <div class="selected-means-del" @click="meansSelectChange(false, item)">删除</div>
              </div>
            </template>
          </div>
        </el-form-item>
        <el-form-item label="农事时间" prop="farmDate">
          <el-date-picker v-model="farmDate" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="备注">
          <el-input type="textarea" autosize v-model="form.descriptions"></el-input>
        </el-form-item>

        <el-form-item label="上传照片">
          <el-upload
            action=""
            ref="upload"
            :class="{ hideUpload: hideUploadEdit }"
            :file-list="fileList"
            :limit="1"
            list-type="picture-card"
            accept="image/jpg,image/jpeg,image/png"
            :auto-upload="false"
            :on-change="fileChange"
            :on-remove="fileChange"
          >
            <i class="el-icon-plus"></i>
          </el-upload>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit">立即创建</el-button>
          <el-button @click="closeDg()">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <el-dialog title="农资列表" :visible="meansDgVisible" width="60%" @close="closeMeansDg" destroy-on-close :close-on-click-modal="false">
      <el-table v-if="meansList.length" ref="multipleTable" :data="meansList" stripe>
        <el-table-column label="选择状态" width="100">
          <template slot-scope="scope">
            <el-checkbox
              @change="
                (val) => {
                  meansSelectChange(val, scope.row);
                }
              "
              v-model="scope.row.selected"
            ></el-checkbox>
          </template>
        </el-table-column>
        <el-table-column prop="name" label="农资"> </el-table-column>
        <el-table-column label="规格">
          <template slot-scope="scope">
            {{ scope.row.countPerUnit }}{{ unitSpecs[scope.row.unitSpecs] }}/{{ unitMeasurement[scope.row.unitOfMeasurement] }}
          </template>
        </el-table-column>
        <el-table-column label="库存数">
          <template slot-scope="scope">
            {{ scope.row.availableAmount }}
          </template>
        </el-table-column>
        <el-table-column label="数量">
          <template slot-scope="scope">
            <el-input
              @change="
                (val) => {
                  numInputChange(val, scope.row);
                }
              "
              type="number"
              min="1"
              v-model="scope.row.meansNum"
            ></el-input>
          </template>
        </el-table-column>
        <el-table-column label="总量">
          <template slot-scope="scope"> {{ scope.row.totalMeansVal }}{{ unitSpecs[scope.row.unitSpecs] }} </template>
        </el-table-column>
      </el-table>
      <div slot="footer" class="dialog-footer">
        <el-button @click="closeMeansDg">取 消</el-button>
        <el-button type="primary" @click="submitMeansSelect">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import dayjs from 'dayjs';
import { productionReport, createWorkFarming, farmingList, sysUsers, farmingTypes } from '@/api/api.js';
export default {
  props: {
    dialogVisible: false,
    departmentList: [],
    plantLands: [],
    landInfo: null
  },
  components: {},
  computed: {},
  data() {
    return {
      hideUploadEdit: false,
      rules: {
        farmingTypeId: [{ required: true, message: '请选择农事名称', trigger: 'change' }],
        userId: [{ required: true, message: '请选择操作人', trigger: 'change' }],
        landId: [{ required: true, message: '请选择地块名称', trigger: 'change' }],
        farmDate: [
          {
            validator: this.validateFarmDate,
            required: true
          }
        ]
      },
      form: {
        farmingTypeId: '',
        userId: '',
        landId: '',
        completeArea: '',
        descriptions: ''
      },
      fileList: [],
      farmDate: '',
      meansPageInfo: {
        currentPage: 1,
        pageSize: 99999,
        totalPage: 0
      },
      meansList: [],
      selectIds: [],
      meansDgVisible: false,
      unitSpecs: {},
      unitMeasurement: {},
      selectMeans: [],
      farmingTypeNames: [],
      startDate: '',
      sysUsers: [],
      farmingTypes: []
    };
  },
  created() {
    this.getDicList();
    this.getSysUsers();
    this.getFarmingTypes();
  },
  watch: {
    dialogVisible(val) {
      if (val) {
        this.hideUploadEdit = false;
        this.form = {
          farmingTypeId: '',
          userId: '',
          landId: '',
          completeArea: '',
          descriptions: ''
        };

        this.fileList = [];
        this.farmDate = [];
      }
    }
  },
  methods: {
    getSysUsers() {
      sysUsers({})
        .then((res) => {
          this.sysUsers = res;
        })
        .catch((error) => {});
    },
    getFarmingTypes() {
      farmingTypes({})
        .then((res) => {
          this.farmingTypes = res;
        })
        .catch((error) => {});
    },
    stageChange(val) {
      this.farmingTypes.map((item) => {
        if (item.stageCode == val) {
          this.farmingTypeNames = item.farmingTypes;
        }
      });
      this.form.farmingTypeId = '';
    },
    landChange(val) {
      this.form.landId = val;
      this.getArea();
    },
    userChange(val) {
      this.form.userId = val;
    },
    getArea() {
      this.plantLands.forEach((item) => {
        if (item.id == this.form.landId) {
          this.form.completeArea = item.area;
          return;
        }
      });
    },
    fileChange(file, fileList) {
      this.hideUploadEdit = fileList.length >= 1;
      this.fileList = fileList;
    },
    editMeansList(rows) {
      let list = [];
      rows.forEach((item) => {
        if (item.availableAmount && item.availableAmount > 0) {
          item.meansNum = 1;
          item.totalMeansVal = item.meansNum * item.countPerUnit;
          list.push(item);
        }
      });
      return list;
    },
    addMeans(reLoad) {
      if (!reLoad && this.meansList.length) {
        this.meansDgVisible = true;
        return;
      }
      productionReport({
        pageNum: this.meansPageInfo.currentPage,
        pageSize: this.meansPageInfo.pageSize
      })
        .then((res) => {
          this.meansList = this.editMeansList(res.rows);
          this.meansDgVisible = true;
          this.meansPageInfo.totalPage = res.total;
        })
        .catch((error) => {});
    },
    async getDicList() {
      let res = await this.$store.dispatch('getDicAll');
      this.unitSpecs = res['unit.specs'];
      this.unitMeasurement = res['unit.of.measurement'];
    },
    numInputChange(val, row) {
      let meansList = [];
      if (!val || val < 0) {
        val = 1;
      } else if (val > row.availableAmount) {
        val = row.availableAmount;
      }
      this.meansList.forEach((item) => {
        if (item.id == row.id) {
          item.meansNum = parseInt(val);
          item.totalMeansVal = parseInt(val) * item.countPerUnit;
        }
      });
    },
    closeMeansDg() {},
    meansSelectChange(val, row) {
      this.meansList.forEach((item) => {
        if (item.id == row.id) {
          item.selected = val;
        }
      });
    },
    handleSizeChange(e) {
      this.meansPageInfo.currentPage = e;
      this.addMeans(true);
    },
    submitMeansSelect() {
      this.meansDgVisible = false;
    },
    closeMeansDg() {
      this.meansDgVisible = false;
    },
    getRowKey(row) {
      return row.id;
    },
    getSelectIds() {
      let useStocks = [];
      this.meansList.forEach((item) => {
        let selObj = {};
        if (item.selected) {
          selObj['productionId'] = item.id;
          selObj['amount'] = item.meansNum;
          useStocks.push(selObj);
        }
      });
      return useStocks;
    },
    validateFarmDate(rule, value, callback) {
      this.farmDate && this.farmDate.length ? callback() : callback(new Error(`请选择农事时间`));
    },
    onSubmit() {
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          let useStocks = this.getSelectIds();
          // if (!useStocks.length) {
          //   this.$message({
          //     message: '请添加农资',
          //     type: 'warning'
          //   });
          //   return;
          // }
          let params = { ...this.form };
          if (this.farmDate.length) {
            params.startDate = this.farmDate[0] ? dayjs(this.farmDate[0]).format('YYYY-MM-DD') : '';
            params.endDate = this.farmDate[1] ? dayjs(this.farmDate[1]).format('YYYY-MM-DD') : '';
          }
          params.useStocks = useStocks;
          params.landId = this.landInfo.id;
          params.completeArea = this.landInfo.area;
          let formData = new FormData();
          if (this.fileList && this.fileList.length && this.fileList[0].raw) {
            formData.append('file', this.fileList[0].raw);
          }
          formData.append('requestJson', JSON.stringify({ ...params }));
          createWorkFarming(formData).then((res) => {
            this.$message({
              message: '创建成功',
              type: 'success'
            });
            this.closeDg();
            this.updateList();
          });
        } else {
          return false;
        }
      });
    },
    closeDg() {
      this.fileList = [];
      this.$refs.upload.clearFiles();
      this.$emit('update:dialogVisible', false);
    },
    updateList() {
      this.$emit('updateList');
    }
  }
};
</script>
<style scoped lang="scss">
.flex-row-dia {
  display: flex;
}

.flex-center {
  display: flex;
  height: 40px;
  flex-direction: column;
  justify-content: center;
}
.select-means-wrap {
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  line-height: 15px;
  .means-item {
    display: flex;
    align-items: center;
    color: #313746;
    background: #f7f8fa;
    margin-bottom: 20px;
    border-radius: 8px;
    flex-wrap: wrap;
    .selected-means-del {
      color: #409eff;
      cursor: pointer;
      font-size: 14px;
      width: 60px;
      text-align: center;
    }
    .left {
      flex: 1;
    }
    .row1,
    .row2 {
      width: 50%;
      display: flex;
      align-items: center;
      height: 40px;
      justify-content: space-between;
      padding: 0 20px;
    }
  }
  // span {
  //   width: 50%;
  // }
}
.el-pagination {
  text-align: center;
  margin-top: 10px;
}
</style>
